<template>
    <span v-if="info">{{ info }}</span>
    <v-btn v-else x-small text class="subsecondary" color="secondary" @click="toUpdatelink">
        <v-icon>{{ icon.mdiPlaylistEdit }}</v-icon>
        <span class="mr-1">Edit</span>
    </v-btn>
</template>

<script>
import { mdiPlaylistEdit } from '@mdi/js'
import { ref } from '@vue/composition-api'
// import { Drequest } from '@/plugins/Drequest'

export default {
    props: {
        info: {
            type: String,
            default: null
        },
        link: 0
    },
    data() {
        return {
            icon: { mdiPlaylistEdit }
        }
    },
    methods: {
        toUpdatelink() {
            if (this.link === 1) {
                this.$router.push({ name: 'company.account.setting' })
            }
            if (this.link === 0) {
                this.$router.push({ name: 'user.account.setting' })
            }
        },
    },
}
</script>


<style lang="scss" scoped>
</style>
