<template>
  <div>
    <!-- Personnal infos card -->
    <v-container v-if="company">
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-4 rounded-lg">
            <div class="pa-4 d-flex flex-row">
              <span>Administrator</span>
            </div>
            <v-divider></v-divider>
            <v-card-text class="d-flex">
              <!-- <div class="col-md-5">User name</div> -->
              <div v-if="company.user">{{ company.user.username }}</div>
              <v-btn @click="viewadminprofile" text small color="primary" class="ml-4">View profil</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card outlined class="mb-4 rounded-lg">
            <div class="pa-4">
              Informations
            </div>
            <v-divider></v-divider>
            <v-card-text class="d-flex pa-0">
              <div class="col-md-5">Company</div>
              <div class="col-md-7">
                <update-info :info="company.name" :link="1"></update-info>
                <!-- <span v-if="company.enterprise_form" class="ml-2 primary--text">({{ company.enterprise_form.name.en }})</span> -->
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="d-flex pa-0">
              <div class="col-md-5">Phone number</div>
              <div class="col-md-7">
                <update-info :info="company.telephone" :link="1"></update-info>
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text class="d-flex pa-0">
              <div class="col-md-5">Email </div>
              <div class="col-md-7">
                <update-info :info="company.email" :link="1"></update-info>
              </div>
            </v-card-text>
            <v-card-text class="d-flex pa-0">
              <div class="col-md-5">Website </div>
              <div class="col-md-7">
                <update-info :info="company.website" :link="1"></update-info>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card outlined class="mb-4 rounded-lg">
            <div class="pa-3">
              Company type
            </div>
            <v-divider></v-divider>
            <v-card-text class="" v-if="company.type">
              {{ company.type.designation }}
            </v-card-text>
          </v-card>
          <v-card outlined class="mb-4 rounded-lg">
            <div class="pa-3">
              About {{ company.about }}
            </div>
            <v-divider></v-divider>
            <v-card-text class="">
              <update-info :info="company.about" :link="1"></update-info>
            </v-card-text>
          </v-card>
          <v-card outlined class="mb-4 rounded-lg">
            <div class="pa-3">
              Address
            </div>
            <v-divider></v-divider>
            <v-card-text class="">
              <div class="col-md-5">Address: <update-info :info="company.address" :link="1"></update-info>
              </div>
            </v-card-text>
            <v-card-text class="d-flex flex-nowrap">
              <v-icon>{{ icons.mdiMapMarkerOutline }}</v-icon>
              <update-info v-if="company.country" :info="company.country.name" :link="1"></update-info>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {
  mdiAlertOutline, mdiCloudUploadOutline,
  mdiPhone, mdiMessageText, mdiMapMarker, mdiEmail, mdiWeb,
  mdiKeyboardBackspace, mdiTrashCan, mdiPrinter,
  mdiCalendarRange, mdiMapMarkerOutline,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import UpdateInfo from '@/views/dashboard/components/profil/UpdateInfo.vue'
import { Drequest } from '@/plugins/Drequest'

export default {
  components: {
    UpdateInfo
  },
  data() {
    return {
      company: {},
      dialog: false,
      loading: false,
    }
  },
  methods: {
    viewadminprofile() {
      this.$router.push({ name: 'user.account' })
    },
    fetchCompanyInformation() {
      Drequest.api(`enterprise.fetch?keyid=${this.$store.getters.getCompany.id}`).get((response) => {
        if (response.success === true) {
          this.company = response.enterprise
        }
        else { this.loading = false }
      }).catch((err) => { this.loading = false });
    },
  },
  beforeMount() {
    this.fetchCompanyInformation()
  },
  setup(props) {
    return {
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
        mdiPhone, mdiMessageText, mdiMapMarker, mdiEmail, mdiWeb,
        mdiKeyboardBackspace, mdiTrashCan, mdiPrinter,
        mdiCalendarRange, mdiMapMarkerOutline
      },
    }
  },
}
</script>


<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

.avatar-center {
  top: -3rem;
  left: 3rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}

.membership-pricing {
  text-align: center;

  sup {
    font-size: 3.75rem;
    top: 9px;
  }
}
</style>
